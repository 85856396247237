import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'

import RootLayout from './layouts/RootLayout';
import WorksLayout from './layouts/WorksLayout';
import Home from './pages/Home'
import Works from './pages/Works'
import WorkDetail from './pages/WorkDetail'
import About from './pages/About'
import SonoraAI from './pages/SonoraAI'
import Vloader from './pages/Vloader'
import PrivacyPolicy from './pages/SonoraAI/privacy_policy'
import TermsAndConditions from './pages/SonoraAI/terms_and_conditions'
import EULA from './pages/SonoraAI/eula'
import EULAVloader from './pages/Vloader/eula_vloader'
import TermsAndConditionsVloader from './pages/Vloader/terms_and_conditions_vloader'
import Error from './pages/Error';
import Faucet from './pages/Faucet'; // Faucet bileşenini içe aktar
import SharixDelete from './pages/SharixDelete';
import PrivacyPolicySharix from './pages/SonoraAI/privacy_policy_sharix';
import PrivacyPolicyVloader from './pages/Vloader/privacy_policy_vloader';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route
        path='work'
        element={<WorksLayout />}>
        <Route index element={<Works />} />
        <Route path=':name' element={<WorkDetail />} />
      </Route>
      <Route path='about' element={<About />} />
      <Route path='faucet' element={<Faucet />} />
      <Route path='sharix-delete-account' element={<SharixDelete />} />
      <Route path='sonora-ai' element={<SonoraAI />} />
      <Route path='sonora-ai/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='sharix-ai/privacy-policy' element={<PrivacyPolicySharix />} />
      <Route path='sonora-ai/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='sonora-ai/eula' element={<EULA />} />
      <Route path='vloader' element={<Vloader />} />
      <Route path='vloader/privacy-policy' element={<PrivacyPolicyVloader />} />
      <Route path='vloader/terms-and-conditions' element={<TermsAndConditionsVloader />} />
      <Route path='vloader/eula' element={<EULAVloader />} />
      <Route path='*' element={<Error />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
