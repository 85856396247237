import React, { useState } from 'react';
import styles from './Header.module.css'; // Importing CSS module

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <nav className={styles.navbar}>
            <div className={styles.navbarContainer}>
                <a href="/" className={styles.logo}>asa.</a>

                <button className={styles.menuToggle} onClick={toggleMenu}>
                    <span className="sr-only">{isMenuOpen ? 'Close menu' : 'Open menu'}</span>
                    {isMenuOpen ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    )}
                </button>

                <ul className={`${styles.navLinks} ${isMenuOpen ? styles.open : ''}`}>
                    <li><a href="/" className={styles.navItem}>Home</a></li>
                    <li><a href="/work" className={styles.navItem}>Work</a></li>
                    <li><a href="/about" className={styles.navItem}>About</a></li>
                    <li><a href="/sonora-ai" className={styles.navItem}>Sonora AI</a></li>
                    <li className={styles.vloader}>
                        <a href="/vloader" className={styles.navItem}>Vloader</a>
                        <span className={styles.badge}>🔥 NEW</span>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
