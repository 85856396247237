import React from 'react';

const VloaderPage = () => {
    return (
        <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-8">
            <div className="max-w-screen-xl mx-auto text-center">
                {/* Başlık */}
                <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6">
                    Vloader - Video Downloading App
                </h1>

                {/* Uygulama hakkında bilgi */}
                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Vloader is a cutting-edge video downloading application that allows you to save and manage high-quality videos from various sources. Whether you're a content creator, a casual viewer, or someone looking to organize important video assets, Vloader offers an intuitive platform where convenience meets efficiency.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    With its user-friendly interface, Vloader makes it easy for anyone to download and store videos without the hassle of complicated software or technical hurdles. The intelligent engine is designed to recognize and handle different video formats, ensuring that you can save everything from short clips to full-length features. Whether you’re in the mood to archive tutorials, save memorable clips, or keep your favorite shows for offline viewing, Vloader has you covered.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    One of the most exciting features of Vloader is its ability to process multiple downloads simultaneously, all while preserving high-quality resolution. You can customize the video quality, select preferred file formats, and organize downloads into user-friendly folders. If you’re a content creator seeking inspiration, Vloader can help you store reference videos or gather source material for your projects.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    For those interested in collaboration, Vloader offers tools to share your saved videos with others. You can easily export your stored files and collaborate with friends, fellow creators, or even a global community of users. The app also integrates seamlessly with various cloud services, so you can keep your downloaded content accessible across multiple devices.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Vloader is not just for casual viewers; it’s also perfect for professionals who need offline access to video files. With its robust download manager and the ability to handle large files, you’ll always have what you need right at your fingertips—even without a stable internet connection. The engine learns from your usage, so the more you use the app, the more it can tailor its performance to match your preferences.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Additionally, Vloader is continually updated with new features and improvements, ensuring that you always have access to the latest tools in video downloading. Whether you’re saving educational resources, archiving personal videos, or collecting references for creative projects, Vloader provides an innovative, accessible, and powerful video-downloading experience.
                </p>

                {/* Google Play ve App Store Yönlendirme */}
                <div className="flex justify-center space-x-4 mb-6">
                    <a
                        href="https://play.google.com/store/apps/details?id=video.downloader.videosaver"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                            alt="Google Play"
                            className="h-16"
                        />
                    </a>
                    <a
                        href="https://apps.apple.com/app/id6743373648"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
                            alt="App Store"
                            className="h-16"
                        />
                    </a>
                </div>

                {/* Privacy Policy, Terms, and EULA */}
                <div className="text-lg text-gray-700 dark:text-gray-300">
                    <p className="mb-4">
                        By using Vloader, you agree to our{' '}
                        <a
                            href="/vloader/privacy-policy"
                            className="text-blue-600 dark:text-blue-400 hover:underline"
                        >
                            Privacy Policy
                        </a>
                        ,{' '}
                        <a
                            href="/vloader/terms-and-conditions"
                            className="text-blue-600 dark:text-blue-400 hover:underline"
                        >
                            Terms and Conditions
                        </a>
                        , and{' '}
                        <a
                            href="/vloader/eula"
                            className="text-blue-600 dark:text-blue-400 hover:underline"
                        >
                            End-User License Agreement (EULA)
                        </a>
                        .
                    </p>
                </div>

                {/* Footer */}
                <footer className="mt-10 text-gray-600 dark:text-gray-400">
                    <p>© 2024 Vloader. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default VloaderPage;
